import { ListItem, ListItemText } from '@material-ui/core'

import React from 'react'

function ListLinkItem({ primaryText, secondaryText, onClick }) {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItem>
  )
}

export default ListLinkItem
