import {
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { SendRequest, components, constants } from 'cng-web-lib'
import {
  faCalendarAlt,
  faClock,
  faTag
} from '@fortawesome/free-solid-svg-icons'

import { ArticleCategory as Category } from 'src/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EssenceApiUrls as Url } from 'src/apiUrls'
import apiUrlToFrontEndCategoryMap from './apiUrlToFrontEndCategoryMap'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

const { AxiosMethod } = constants
const { RenderHtml, VideoPlayer } = components

const useClasses = makeStyles({
  paper: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '20px'
  },
  header: {
    backgroundColor: 'black',
    color: 'white'
  },
  video: {
    height: '425px !important'
  },
  img: {
    height: '425px',
    maxWidth: '100%',
    objectFit: 'contain'
  },
  whiteDivider: {
    background: 'white'
  },
  metadataSection: {
    marginBottom: '20px'
  },
  createdBy: {
    color: 'red'
  },
  eventHeader: {
    color: '#9f346a'
  },
  eventSubHeader: {
    color: '#DC143C'
  },
  summary: {
    fontStyle: 'italic',
    fontSize: '18px',
    fontWeight: 700
  },
  body: {
    '& p': {
      marginBottom: '1rem'
    },
    '& h1, h2, h3, h4 ,h5, h6': {
      marginBottom: '.5rem'
    },
    '& ol, ul li': {
      marginLeft: '1em'
    }
  },
  divider: {
    marginTop: '40px',
    marginBottom: '20px'
  },
  chip: {
    margin: '0.5px'
  }
})

function ArticlePage() {
  const { id } = useParams()
  const classes = useClasses()
  const [datum, setDatum] = useState({})

  useEffect(() => {
    const getRequest = new SendRequest()
    getRequest.execute(AxiosMethod.GET, `${Url.ARTICLES}/${id}`, {}, (body) => {
      setDatum(convertToLocalData(body.data.Data))
    })

    return () => {
      getRequest.abort()
    }
  }, [id])

  function convertToLocalData(remoteDatum) {
    const datum = {
      category: apiUrlToFrontEndCategoryMap[remoteDatum.Category],
      createdBy: remoteDatum.CreatedBy,
      createdDate: remoteDatum.CreatedDate,
      id: remoteDatum.Id,
      lastUpdatedDate: remoteDatum.LastUpdatedDate,
      subTitle: remoteDatum.SubTitle,
      summary: remoteDatum.Summary,
      tags: remoteDatum.Tags,
      title: remoteDatum.Title
    }

    if (datum.category === Category.VIDEO) {
      datum.link = remoteDatum.Body
    } else {
      datum.body = remoteDatum.Body
      datum.image = Url.CONTENT + remoteDatum.HeaderGraphicFilename
    }

    if (datum.category === Category.EVENT) {
      datum.registrationStartDate = remoteDatum.RegistrationStartDate
      datum.registrationEndDate = remoteDatum.RegistrationEndDate
      datum.eventStartDate = remoteDatum.EventStartDate
      datum.eventEndDate = remoteDatum.EventEndDate
      datum.eventPrice = remoteDatum.EventPrice
    }

    return datum
  }

  function renderMedia(datum) {
    let component = null

    if (datum.category !== undefined) {
      if (datum.category === Category.VIDEO) {
        component = <VideoPlayer className={classes.video} url={datum.link} />
      } else {
        component = (
          <img className={classes.img} src={datum.image} alt='Image' />
        )
      }
    }

    return component
  }

  function renderMetadata(datum) {
    let component = null

    if (datum.category === Category.EVENT) {
      component = renderEventMetadata(datum)
    } else {
      component = renderArticleMetadata(datum)
    }

    return component
  }

  function renderEventMetadata(datum) {
    return (
      <Grid container className={classes.metadataSection} spacing={2}>
        <Grid item xs={12}>
          <Typography
            className={classes.eventHeader}
            variant='h3'
            align='center'
          >
            Event
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.eventSubHeader}
              variant='h4'
              align='center'
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
              &nbsp;Registration Period
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center'>
              From:&nbsp;{renderDatetime(datum.registrationStartDate)}
            </Typography>
          </Grid>
          {datum.registrationEndDate ? (
            <Grid item xs={12}>
              <Typography align='center'>
                To:&nbsp;{renderDatetime(datum.registrationEndDate)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.eventSubHeader}
              variant='h4'
              align='center'
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
              &nbsp;Event Date
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center'>
              From:&nbsp;{renderDatetime(datum.eventStartDate)}
            </Typography>
          </Grid>
          {datum.eventEndDate ? (
            <Grid item xs={12}>
              <Typography align='center'>
                To:&nbsp;{renderDatetime(datum.eventEndDate)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.eventSubHeader}
              variant='h4'
              align='center'
            >
              <FontAwesomeIcon icon={faTag} />
              &nbsp;Price
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center'>
              {datum.eventPrice ? `S$${datum.eventPrice}` : 'Free'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  function renderDatetime(datetime) {
    return (
      <>
        <FontAwesomeIcon icon={faCalendarAlt} />
        &nbsp;{getDate(datetime)}&nbsp;
        <FontAwesomeIcon icon={faClock} />
        &nbsp;{getTime(datetime)}
      </>
    )
  }

  function renderArticleMetadata(datum) {
    return (
      <Grid container className={classes.metadataSection}>
        <Grid item xs={12}>
          <Typography className={classes.createdBy} variant='h5'>
            <strong>{datum.createdBy}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {renderDatetime(datum.createdDate)}
        </Grid>
        <Grid item xs={12}>
          <i>Last Updated: {datum.lastUpdatedDate}</i>
        </Grid>
      </Grid>
    )
  }

  function getDate(datetime) {
    let date = null
    if (datetime) {
      date = datetime.split(' ')[0]
    }
    return date
  }

  function getTime(datetime) {
    let time = null
    if (datetime) {
      time = datetime.split(' ')[1]
    }
    return time
  }

  function renderTags(tags = []) {
    return tags.map((tag) => {
      return (
        <Chip
          className={classes.chip}
          key={tag}
          label={tag}
          clickable
          color='primary'
        />
      )
    })
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        <Grid
          container
          className={classes.header}
          spacing={1}
          alignItems='flex-start'
        >
          <Grid item xs={7}>
            {renderMedia(datum)}
          </Grid>
          <Grid item container xs={5} spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h1'>{datum.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.whiteDivider }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4'>{datum.subTitle}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {renderMetadata(datum)}
        <Grid container className={classNames(classes.summary, classes.body)}>
          <Grid item xs={12}>
            <RenderHtml content={datum.summary} />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container className={classes.body}>
          <Grid item xs={12}>
            <RenderHtml content={datum.body} />
          </Grid>
          <Grid item xs={12}>
            Tags: {renderTags(datum.tags)}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default ArticlePage
