import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  SendRequest,
  components,
  constants,
  useNotification
} from 'cng-web-lib'

import { EssenceApiUrls as Url } from 'src/apiUrls'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

const { AxiosMethod, NotificationType } = constants
const { RenderHtml } = components

const useClasses = makeStyles({
  paper: {
    marginTop: '50px',
    marginBottom: '50px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  content: {
    '& p': {
      marginBottom: '1rem'
    },
    '& h1, h2, h3, h4 ,h5, h6': {
      marginBottom: '.5rem'
    },
    '& ol, ul li': {
      marginLeft: '1em'
    }
  }
})

function SiteContentPage() {
  const classes = useClasses()
  const { code } = useParams()
  const { showNotification } = useNotification()
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    setData({})
    const getRequest = new SendRequest()
    getRequest.execute(
      AxiosMethod.GET,
      Url.SITE_CONTENT + code.toUpperCase().replace(/-/g, ''),
      {},
      (body) => {
        setData(convertToLocalData(body.data.Data))
      },
      (error) => {
        showNotification(
          NotificationType.ERROR,
          'Failed to fetch content.',
          error
        )
      },
      () => {
        setIsLoading(false)
      }
    )

    return () => {
      getRequest.abort()
    }
  }, [code])

  function convertToLocalData(remoteData = {}) {
    return {
      title: remoteData.Title,
      content: remoteData.Body
    }
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          {isLoading ? (
            <Grid item container xs={12} justify='center'>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item container xs={12} justify='center'>
                <Typography variant='h2'>{data.title}</Typography>
              </Grid>
              <Grid item xs={11} className={classes.content}>
                <RenderHtml content={data.content} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  )
}

export default SiteContentPage
