import { ArticleCategory as Category } from 'src/constants'

const apiUrlToFrontEndCategoryMap = {
  ARTICLE: Category.ARTICLE,
  EVENT: Category.EVENT,
  NEWS: Category.NEWS,
  VIDEO: Category.VIDEO
}

export default apiUrlToFrontEndCategoryMap
