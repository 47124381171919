import { CardMedia } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core/styles'

const { VideoPlayer } = components

const useClasses = makeStyles({
  cardMedia: {
    height: '200px !important'
  }
})

function CardVideo({ url, isPlaying, shouldLoop, hasControls }) {
  const classes = useClasses()

  return (
    <CardMedia
      component={VideoPlayer}
      className={classes.cardMedia}
      url={url}
      isPlaying={isPlaying}
      shouldLoop={shouldLoop}
      hasControls={hasControls}
    />
  )
}

export default CardVideo
