import Carousel, { Slide } from './Carousel'
import React, { useEffect, useState } from 'react'
import { SendRequest, constants } from 'cng-web-lib'

import { EssenceApiUrls as Url } from 'src/apiUrls'

const { AxiosMethod } = constants

function CarouselSection() {
  const [data, setData] = useState([])

  useEffect(() => {
    const getRequest = sendGetRequest()

    return () => {
      getRequest.abort()
    }
  }, [])

  function sendGetRequest() {
    const sendRequest = new SendRequest()
    sendRequest.execute(AxiosMethod.GET, Url.CAROUSEL, {}, (body) => {
      setData(convertToLocalData(body.data.Data))
    })
    return sendRequest
  }

  function convertToLocalData(remoteData) {
    const data = remoteData.map((remoteDatum) => {
      return {
        id: remoteDatum.Id,
        src: Url.CONTENT + remoteDatum.GraphicFilename,
        alt: remoteDatum.AltText,
        header: remoteDatum.DisplayTitle || '',
        caption: remoteDatum.Description,
        positionX: remoteDatum.PositionX,
        positionY: remoteDatum.PositionY,
        url: remoteDatum.UrlLink
      }
    })
    return data
  }

  return (
    <Carousel>
      {data.map((datum) => {
        return (
          <Slide
            key={datum.id}
            image={{ src: datum.src, alt: datum.alt }}
            caption={{
              header: datum.header,
              content: datum.caption,
              url: datum.url,
              positionX: datum.positionX,
              positionY: datum.positionY
            }}
          />
        )
      })}
    </Carousel>
  )
}

export default CarouselSection
