import React, { useEffect, useState } from 'react'
import { SendRequest, components, constants } from 'cng-web-lib'

import { Link } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { EssenceApiUrls as Url } from 'src/apiUrls'
import pathMap from 'src/paths/pathMap'

const { AxiosMethod } = constants
const {
  table: { CngTable, DateTimeRangeFilter }
} = components

function TableSection({ category }) {
  const ARTICLES_PATH = `${pathMap.HOME}/${category}`
  const [data, setData] = useState([])
  const columns = [
    {
      field: 'datetime',
      title: 'Date',
      type: 'datetime',
      filtering: true,
      filterComponent: DateTimeRangeFilter
    },
    {
      field: 'title',
      title: 'News',
      render: (rowData) => {
        return <Link to={rowData.detailLink}>{rowData.title}</Link>
      },
      filtering: true
    }
  ]

  useEffect(() => {
    const getRequest = new SendRequest()
    getRequest.execute(
      AxiosMethod.GET,
      Url.getArticle(category),
      {},
      (body) => {
        setData(convertToLocalData(body.data.Data))
      }
    )

    return () => {
      getRequest.abort()
    }
  }, [])

  function convertToLocalData(remoteData) {
    return remoteData.map((remoteDatum) => {
      const detailLink = `${ARTICLES_PATH}/${remoteDatum.Id}`
      return {
        id: remoteDatum.Id,
        title: remoteDatum.Title,
        datetime: remoteDatum.PublishStartDate,
        detailLink
      }
    })
  }

  return (
    <Paper>
      <CngTable columns={columns} data={data} options={{ pageSize: 5 }} />
    </Paper>
  )
}

export default TableSection
