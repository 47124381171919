import { Card, CardContent, CardHeader } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classNames from 'classnames'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  caption: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backgroundPosition: '50% 0',
    borderWidth: '0px',
    color: 'white',
    fontSize: '15px',
    fontWeight: '300',
    letterSpacing: '0px',
    lineHeight: '22px',
    margin: '0px',
    maxHeight: 'none',
    maxWidth: '500px',
    minHeight: '0px',
    minWidth: '500px',
    opacity: 1,
    padding: '20px',
    textAlign: 'inherit',
    whiteSpace: 'normal',
    zIndex: 0
  },
  captionRightTop: {
    position: 'relative',
    left: '90%',
    bottom: '100%',
    transform: 'translate(-100%)'
  },
  captionRightCenter: {
    position: 'relative',
    left: '90%',
    bottom: '50%',
    transform: 'translate(-100%, -50%)'
  },
  captionRightBottom: {
    position: 'relative',
    left: '90%',
    bottom: '10%',
    transform: 'translate(-100%, -100%)'
  },
  captionCenterTop: {
    position: 'relative',
    left: '50%',
    bottom: '100%',
    transform: 'translateX(-50%)'
  },
  captionCenterCenter: {
    position: 'relative',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, -50%)'
  },
  captionCenterBottom: {
    position: 'relative',
    left: '50%',
    bottom: '10%',
    transform: 'translate(-50%, -100%)'
  },
  captionLeftTop: {
    position: 'relative',
    left: '10%',
    bottom: '100%'
  },
  captionLeftCenter: {
    position: 'relative',
    left: '10%',
    bottom: '50%',
    transform: 'translateY(-50%)'
  },
  captionLeftBottom: {
    position: 'relative',
    left: '10%',
    bottom: '10%',
    transform: 'translateY(-100%)'
  },
  a: {
    color: 'aqua'
  }
})

function Caption({ positionX, positionY, header, content, url }) {
  const classes = useClasses()

  return (
    <Card
      className={classNames(
        classes.caption,
        classes[`caption${positionX}${positionY}`]
      )}
    >
      <CardHeader title={header} />
      <CardContent>
        {content ? (
          <>
            {content}{' '}
            {url ? (
              <a href={url} className={classes.a}>
                Read more <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            ) : null}
          </>
        ) : (
          ''
        )}
      </CardContent>
    </Card>
  )
}

export default Caption
