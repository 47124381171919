import { CardMedia } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  cardMedia: {
    height: '200px'
  }
})

function CardImage({ src }) {
  const classes = useClasses()

  return <CardMedia src={src} component='img' className={classes.cardMedia} />
}

export default CardImage
