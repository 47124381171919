import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'

const { RenderHtml } = components

const useClasses = makeStyles({
  title: {
    height: '23px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  cardMedia: {
    height: '200px'
  },
  summary: {
    height: '65px',
    overflow: 'hidden',
    '& p': {
      height: '65px',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})

const TitleToolTip = withStyles((theme) => {
  return {
    tooltip: {
      fontSize: theme.typography.pxToRem(16)
    }
  }
})(Tooltip)

function Cards({
  header,
  data,
  renderCardMedia = () => {
    return null
  },
  renderButtonSection = () => {
    return null
  }
}) {
  const classes = useClasses()
  const history = useHistory()

  function onClick(datum) {
    history.push(datum.detailLink)
  }

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={12}>
        <Typography variant='h2' gutterBottom>
          {header}
        </Typography>
        <Divider />
      </Grid>
      {data.map((datum) => {
        const date = datum.datetime ? datum.datetime.split(' ')[0] : '-'
        const time = datum.datetime ? datum.datetime.split(' ')[1] : '-'
        return (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card>
              <CardActionArea
                onClick={() => {
                  onClick(datum)
                }}
              >
                {renderCardMedia(datum)}
                <CardContent>
                  <TitleToolTip title={datum.title}>
                    <Typography
                      variant='h4'
                      gutterBottom
                      className={classes.title}
                    >
                      {datum.title}
                    </Typography>
                  </TitleToolTip>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    color='textSecondary'
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    &nbsp;<span>{date}</span>&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faClock} />
                    &nbsp;<span>{time}</span>
                  </Typography>
                  <Typography variant='body2' className={classes.summary}>
                    <RenderHtml content={datum.summary} />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
      <Grid item>{renderButtonSection()}</Grid>
    </Grid>
  )
}

export default Cards
export { default as CardImage } from './CardImage'
export { default as CardVideo } from './CardVideo'
export { default as MoreButton } from './MoreButton'
