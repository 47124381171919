import ArticlesSection from './ArticlesSection'
import CarouselSection from './CarouselSection'
import { Container } from '@material-ui/core'
import EventsSection from './EventsSection'
import NewsSection from './NewsSection'
import React from 'react'
import SearchSection from './SearchSection'
import VideosSection from './VideosSection'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  bottomSpace: {
    paddingBottom: '20px'
  }
})

function HomePage() {
  const classes = useClasses()

  return (
    <>
      <SearchSection />
      <div className={classes.bottomSpace}>
        <CarouselSection />
      </div>
      <Container className={classes.bottomSpace}>
        <VideosSection />
        <NewsSection />
        <EventsSection />
        <ArticlesSection />
      </Container>
    </>
  )
}

export default HomePage
