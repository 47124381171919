import RMUCarousel from 'react-material-ui-carousel'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  indicators: {
    bottom: '10px',
    justifyContent: 'center',
    position: 'absolute'
  }
})

function Carousel({ children }) {
  const classes = useClasses()

  return (
    <RMUCarousel indicatorContainerProps={{ className: classes.indicators }}>
      {children}
    </RMUCarousel>
  )
}

export default Carousel
export { default as Slide } from './Slide'
