import Cards, { CardImage, MoreButton } from './Cards'
import React, { useEffect, useState } from 'react'
import { SendRequest, constants } from 'cng-web-lib'

import { ArticleCategory as Category } from 'src/constants'
import { EssenceApiUrls as Url } from 'src/apiUrls'
import pathMap from 'src/paths/pathMap'

const { AxiosMethod } = constants

function NewsSection() {
  const NEWS_PATH = `${pathMap.HOME}/${Category.NEWS}`
  const [data, setData] = useState([])

  useEffect(() => {
    const getRequest = sendGetRequest()

    return () => {
      getRequest.abort()
    }
  }, [])

  function sendGetRequest() {
    const sendRequest = new SendRequest()
    sendRequest.execute(AxiosMethod.GET, Url.NEWEST_3_NEWS, {}, (body) => {
      setData(convertToLocalData(body.data.Data))
    })
    return sendRequest
  }

  function convertToLocalData(remoteData) {
    const data = remoteData.map((remoteDatum) => {
      const detailLink = `${NEWS_PATH}/${remoteDatum.Id}`
      return {
        id: remoteDatum.Id,
        title: remoteDatum.Title,
        image: Url.CONTENT + remoteDatum.HeaderGraphicFilename,
        summary: remoteDatum.Summary,
        datetime: remoteDatum.PublishStartDate,
        detailLink
      }
    })
    return data
  }

  return (
    <Cards
      header='Latest News'
      data={data}
      renderCardMedia={(news) => {
        return <CardImage src={news.image} />
      }}
      renderButtonSection={() => {
        return <MoreButton listUrl={NEWS_PATH}>More News</MoreButton>
      }}
    />
  )
}

export default NewsSection
