import CardsSection from './CardsSection'
import { Container } from '@material-ui/core'
import React from 'react'
import TableSection from './TableSection'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'

const useClasses = makeStyles({
  topSpace: {
    marginTop: '20px'
  },
  bottomSpace: {
    marginBottom: '20px'
  }
})

function ListPage() {
  const { category } = useParams()
  const classes = useClasses()

  return (
    <Container>
      <div className={classes.topSpace}>
        <CardsSection category={category} />
      </div>
      <div className={classes.bottomSpace}>
        <TableSection category={category} />
      </div>
    </Container>
  )
}

export default ListPage
