import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  List,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { SendRequest, components, constants } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import ListLinkItem from './ListLinkItem'
import SearchForm from '../SearchForm'
import { EssenceApiUrls as Url } from 'src/apiUrls'
import _ from 'lodash'
import apiUrlToFrontEndCategoryMap from '../apiUrlToFrontEndCategoryMap'
import { generatePath } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import pathMap from 'src/paths/pathMap'

const { AxiosMethod } = constants
const { RenderHtml } = components

const useClasses = makeStyles({
  container: {
    padding: '10px'
  }
})

function SearchPage() {
  const classes = useClasses()
  const history = useHistory()
  const location = useLocation()
  const queryParams = useQuery()
  const query = queryParams.get('query')
  const page = queryParams.get('page')
  const filter = queryParams.get('filter')
  const [hits, setHits] = useState([])
  const [totalHits, setTotalHits] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setHits([])
    setTotalHits(0)
    const request = new SendRequest()
    request.execute(
      AxiosMethod.GET,
      `${Url.ELASTICSEARCH}?query=${query}&page=${page}&filter=${filter}`,
      {},
      (body) => {
        setHits(body.data.Data.hits.hits)
        setTotalHits(body.data.Data.hits.total.value)
      },
      () => { console.log("On load effect..") },
      () => {
        setIsLoading(false)
      }
    )

    return () => {
      request.abort()
    }
  }, [query, page, filter])

  function getSecondaryText(hit) {
    let secondaryText = ''
    const summary = hit._source.summary

    if (summary) {
      secondaryText = removeEnterKeys(summary.replace(/<\/?[^>]+(>|$)/g, ''))
    } else {
      secondaryText = removeEnterKeys(
        hit._source.body.replace(/<\/?[^>]+(>|$)/g, '')
      )
    }

    if (hit.highlight) {
      const highlight = hit.highlight.body[0]
      if (highlight.length > 50) {
        secondaryText = removeEnterKeys(highlight)
      }
    }

    return secondaryText
  }

  function removeEnterKeys(text) {
    let textArray = text.split(/\r?\n/)
    let displayedText = ''
    for (text of textArray) {
      if (
        displayedText.length + text.length <= 250 ||
        displayedText.length === 0
      ) {
        displayedText += ` ${text}`
      }
    }
    return displayedText
  }

  function navigateToDetailPage(id, source) {
    let url = '/404'

    if (source.category) {
      url = `${pathMap.HOME}/${
        apiUrlToFrontEndCategoryMap[source.category]
      }/${id}`
    } else if (source.code) {
      url = generatePath(pathMap.SITE_CONTENT_VIEW, { code: source.code })
    }

    history.push(url)
  }

  function renderPageButtons(numOfResults) {
    let number = Math.ceil(numOfResults / 5)

    if (number === 0) {
      number = 1
    }

    return _.times(number, (i) => {
      let variant = undefined

      if (`${i + 1}` === page) {
        variant = 'contained'
      }

      return (
        <Button
          key={i + 1}
          color='primary'
          onClick={() => {
            onPageButtonClick(i + 1)
          }}
          variant={variant}
        >
          {i + 1}
        </Button>
      )
    })
  }

  function onPageButtonClick(newPage) {
    history.push(
      `${location.pathname}?query=${query}&page=${newPage}&filter=${filter}`
    )
  }

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant='h2'>
            <strong>Search</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchForm value={query} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <List component='nav'>
                {hits.map((hit, index, hits) => {
                  return (
                    <>
                      <ListLinkItem
                        key={hit._id}
                        primaryText={hit._source.title}
                        secondaryText={
                          <RenderHtml content={getSecondaryText(hit)} />
                        }
                        onClick={() => {
                          navigateToDetailPage(hit._id, hit._source)
                        }}
                      />
                      {index < hits.length - 1 ? <Divider /> : null}
                    </>
                  )
                })}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? null : (
            <ButtonGroup>{renderPageButtons(totalHits)}</ButtonGroup>
          )}
        </Grid>
      </Grid>
    </>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default SearchPage
