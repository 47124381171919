import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'

const {
  button: { CngPrimaryButton }
} = components

function MoreButton({ children, listUrl }) {
  const history = useHistory()

  function navigateToListUrl(listUrl) {
    history.push(listUrl)
  }

  return (
    <CngPrimaryButton
      onClick={() => {
        navigateToListUrl(listUrl)
      }}
    >
      {children}
    </CngPrimaryButton>
  )
}

export default MoreButton
