import Cards, { CardImage, CardVideo } from '../Cards'
import React, { useEffect, useState } from 'react'
import { SendRequest, constants } from 'cng-web-lib'

import { ArticleCategory as Category } from 'src/constants'
import { EssenceApiUrls as Url } from 'src/apiUrls'
import inflection from 'inflection'
import pathMap from 'src/paths/pathMap'

const { AxiosMethod } = constants

function CardsSection({ category }) {
  const ARTICLES_PATH = `${pathMap.HOME}/${category}`
  const ARTICLE_TITLE = inflection.camelize(category.replace(/-/g, '_'))
  const convertToLocalData = makeConvertToLocalData(category)
  const renderCardMedia = makeRenderCardMedia(category)
  const [data, setData] = useState([])

  useEffect(() => {
    const getRequest = new SendRequest()
    getRequest.execute(
      AxiosMethod.GET,
      Url.getNewest3Article(category),
      {},
      (body) => {
        setData(convertToLocalData(body.data.Data))
      }
    )

    return () => {
      getRequest.abort()
    }
  }, [])

  function makeConvertToLocalData(category) {
    let convertToLocalData = undefined

    switch (category) {
      case Category.ARTICLE:
      case Category.EVENT:
      case Category.NEWS:
        convertToLocalData = convertToLocalArticleData
        break
      case Category.VIDEO:
        convertToLocalData = convertToLocalVideoData
        break
    }

    return convertToLocalData
  }

  function convertToLocalArticleData(remoteData) {
    return remoteData.map((remoteDatum) => {
      const detailLink = `${ARTICLES_PATH}/${remoteDatum.Id}`
      return {
        id: remoteDatum.Id,
        title: remoteDatum.Title,
        image: Url.CONTENT + remoteDatum.HeaderGraphicFilename,
        summary: remoteDatum.Summary,
        datetime: remoteDatum.PublishStartDate,
        detailLink
      }
    })
  }

  function convertToLocalVideoData(remoteData) {
    return remoteData.map((remoteDatum) => {
      const detailLink = `${ARTICLES_PATH}/${remoteDatum.Id}`
      return {
        id: remoteDatum.Id,
        title: remoteDatum.Title,
        link: remoteDatum.Body,
        summary: remoteDatum.Summary,
        datetime: remoteDatum.PublishStartDate,
        detailLink,
        playing: false,
        loop: false,
        controls: true
      }
    })
  }

  function makeRenderCardMedia(category) {
    let renderCardMedia = undefined

    switch (category) {
      case Category.ARTICLE:
      case Category.EVENT:
      case Category.NEWS:
        renderCardMedia = renderCardImage
        break
      case Category.VIDEO:
        renderCardMedia = renderCardVideo
        break
    }

    return renderCardMedia
  }

  function renderCardImage(article) {
    return <CardImage src={article.image} />
  }

  function renderCardVideo(video) {
    return (
      <CardVideo
        url={video.link}
        isPlaying={video.playing}
        shouldLoop={video.loop}
        hasControls={video.controls}
      />
    )
  }

  return (
    <Cards
      header={ARTICLE_TITLE}
      data={data}
      renderCardMedia={renderCardMedia}
    />
  )
}

export default CardsSection
