import { Grid } from '@material-ui/core'
import React from 'react'
import SearchForm from './SearchForm'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  container: {
    padding: '10px'
  }
})

function SearchSection() {
  const classes = useClasses()

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <SearchForm />
      </Grid>
    </Grid>
  )
}

export default SearchSection
