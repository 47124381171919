import { Yup, components, constants } from 'cng-web-lib'

import { Grid } from '@material-ui/core'
import React from 'react'
import pathMap from 'src/paths/pathMap'
import { useHistory } from 'react-router-dom'

const { FormState } = constants
const {
  form: {
    CngForm,
    field: { CngTextField }
  }
} = components

function SearchForm({ value }) {
  const history = useHistory()

  function onSubmit(datum = '') {
    history.push(
      `${pathMap.SEARCH_VIEW}?query=${datum.search}&page=1&filter=All`
    )
  }

  return (
    <CngForm
      formState={FormState.COMPLETED}
      formikProps={{
        initialValues: { search: value },
        validationSchema: Yup.object({ search: Yup.string() }),
        onSubmit
      }}
      renderBodySection={() => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <CngTextField
                variant='standard'
                name='search'
                placeholder='Search the website'
              />
            </Grid>
          </Grid>
        )
      }}
      renderButtonSection={() => null}
    />
  )
}

export default SearchForm
