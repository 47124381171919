import Caption from './Caption'
import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useClasses = makeStyles({
  dimension: {
    height: '525px',
    width: '100%',
    ['@media only screen and (max-width: 992px)']: { height: '450px' },
    ['@media only screen and (max-width: 768px)']: { height: '300px' },
    ['@media only screen and (max-width: 576px)']: { height: '150px' }
  },
  img: {
    objectFit: 'cover'
  }
})

function Slide({ image, caption }) {
  const classes = useClasses()

  return (
    <div className={classes.dimension}>
      <img
        src={image.src}
        alt={image.alt}
        className={classNames(classes.dimension, classes.img)}
      />
      <Caption
        positionX={caption.positionX}
        positionY={caption.positionY}
        header={caption.header}
        content={caption.content}
        url={caption.url}
      />
    </div>
  )
}

export default Slide
